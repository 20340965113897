import { uuid4 } from '@sentry/utils';
import { useState } from 'react';

import { type ModelsTTSScript } from '@lp-lib/api-service-client/public';
import { type TitleBlockV2 } from '@lp-lib/game';
import { teamIntroOpeningScriptSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/teamIntroOpeningScript';
import { type TeamIntroOpeningScriptVariable } from '@lp-lib/shared-schema/src/ai/variables/types/teamIntroOpeningScript';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { apiService } from '../../../../services/api-service';
import { fromMediaDTO } from '../../../../utils/api-dto';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { TagQuery } from '../../../../utils/TagQuery';
import { MultilineCardTTSEditor } from '../../../VoiceOver/MultilineCardTTSEditor';
import { useEditor } from '../../Blocks/Common/Editor/EditorUtilities';
import { useTTSScriptsUpdater } from '../../Blocks/TitleV2/TitleBlockTTSScriptsUpdater';
import { makeExampleVariableRegistrySnapshot } from '../../hooks/useCreateGameInfoSnapshot';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { type ActivityEditorProps } from '../types';
import { UGCTextDiff, useOpenUGCDiffModal } from '../UGCDiffModal';
import { log, UGCUtils } from '../utils';

async function runAI(
  packId: string,
  block: TitleBlockV2,
  prompt: string,
  callback: (
    data: ReturnType<typeof teamIntroOpeningScriptSchema.parse>
  ) => Promise<void>
) {
  const q = new TagQuery(block.fields.ttsScripts);
  const script = q.selectFirst(['introduction'])?.script;
  const variables: TeamIntroOpeningScriptVariable = {
    currentScript: script ?? '',
  };
  const resp = await apiService.gamePack.generateBlock(packId, {
    blockId: block.id,
    userPrompt: prompt,
    variables,
  });
  log.info('generate block response', { data: resp.data });
  for (const step of resp.data.runSteps) {
    if (UGCUtils.VaildRunStep(step)) {
      const fn = step.stepDetails.tool_calls[0].function;
      await UGCUtils.UpdateWithLogging(packId, block, fn, async (data) => {
        const output = teamIntroOpeningScriptSchema.parse(data);
        await callback(output);
      });
      return;
    }
  }
  throw new Error('No valid run step found');
}

export async function teamIntroGenAIAction(
  packId: string,
  block: TitleBlockV2,
  prompt: string
) {
  await runAI(packId, block, prompt, async (data) => {
    const ttsScript: ModelsTTSScript = {
      id: uuid4(),
      script: data.openingScript,
      tags: ['introduction'],
    };
    await apiService.block.updateFieldV2(block, 'ttsScripts', [ttsScript]);
  });
}

export function TeamIntroActivityEditor(
  props: ActivityEditorProps<TitleBlockV2>
): JSX.Element | null {
  const { packId, block, asset } = props;
  const { updateField } = useEditor(props);
  const updater = useTTSScriptsUpdater(updateField, props.block);
  const [script, setScript] = useState(
    updater.getSingleTTSScript(['introduction'])?.script
  );
  const card = block.fields.cards?.find(
    (card) => card.teamIntroEnabled === true
  );

  const url = MediaUtils.PickMediaUrl(fromMediaDTO(asset.media), {
    priority: ImagePickPriorityHighToLow,
  });

  const updateScript = (script: string) => {
    const tags = ['introduction'];
    if (card?.id) tags.push(card.id);
    setScript(script);
    updater.handleTTSScriptUpdate({
      tags,
      script,
    });
  };

  const openDiffModal = useOpenUGCDiffModal();

  const {
    state: { state, error },
    call: onSubmitPrompt,
  } = useLiveAsyncCall(async (prompt: string) => {
    await runAI(packId, block, prompt, async (data) => {
      if (script) {
        const confirm = await openDiffModal({
          children: (
            <UGCTextDiff oldValue={script} newValue={data.openingScript} />
          ),
        });
        if (confirm.result !== 'confirmed') return;
      }
      updateScript(data.openingScript);
    });
    return true;
  });

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='w-full h-full relative'>
        {url && (
          <img
            src={url}
            alt='background'
            className='-z-1 absolute top-0 left-1/2 transform -translate-x-1/2 w-4/5'
          />
        )}
        <div className='w-148 h-65 absolute left-1/2 transfrom -translate-x-1/2 bottom-0'>
          <MultilineCardTTSEditor
            label='Host Opening Script'
            settings={block.fields.ttsOptions?.at(0)}
            value={script}
            onBeforeRender={async (script) => {
              if (!script) return null;
              const variables = makeExampleVariableRegistrySnapshot();
              return (await variables.render(script)).script;
            }}
            onChange={(v) => {
              updateScript(v);
              props.analytics.trackTeamIntroScriptEdited({
                blockId: block.id,
                blockType: block.type,
                script: v,
              });
            }}
          />
        </div>
      </div>
      <UGCActivityPromptEditor
        enabled={!!props.enableAI}
        onSubmit={onSubmitPrompt}
        isSubmitting={state.isRunning}
        ctxLabel={`Editing ${asset.primaryText}`}
        error={error}
      />
    </div>
  );
}
