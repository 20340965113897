import { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { BrowserTimeoutCtrl } from '../../../utils/BrowserTimeoutCtrl';
import { err2s } from '../../../utils/common';
import { AIIcon } from '../../icons/AIIcon';
import { ChatSendIcon } from '../../icons/Chat/ChatSendIcon';
import { Loading } from '../../Loading';

type EditorProps = {
  className?: string;
  enabled: boolean;
  onSubmit: (prompt: string) => Promise<boolean>;
  isSubmitting: boolean;
  active?: boolean;
  autoFocus?: boolean;
  ctxLabel?: string;
  error?: unknown;
};

export function CustomGamePackPromptEditor(props: EditorProps) {
  const { onSubmit, isSubmitting, error } = props;
  const [prompt, setPrompt] = useState('');
  const [active, setActive] = useState(!!props.active);
  const ref = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showError, setShowError] = useState(false);

  const activateEditor = () => {
    setActive(true);
    requestAnimationFrame(() => ref.current?.focus());
  };

  const submit = async () => {
    ref.current?.blur();
    const succeeded = await onSubmit(prompt);
    if (!succeeded) {
      ref.current?.focus();
      return;
    }
    setActive(false);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.metaKey) {
      await submit();
    }
  };

  useEffect(() => {
    if (!ref.current || !props.autoFocus) return;
    ref.current.focus();
  }, [props.autoFocus]);

  useEffect(() => {
    if (!error) return;
    setShowError(true);
    const ctrl = new BrowserTimeoutCtrl();
    ctrl.set(() => setShowError(false), 3000);
    return () => ctrl.clear();
  }, [error]);

  useOutsideClick(containerRef, () => {
    if (prompt || isSubmitting) return;
    setActive(false);
  });

  return (
    <div
      className={`w-full flex flex-col items-center justify-center gap-2 ${
        props.className ?? ''
      }`}
    >
      <div
        ref={containerRef}
        className={`${active ? 'w-160 h-30' : 'w-50 h-10'} transition-size`}
      >
        <div className={`w-full h-full ${active ? 'block' : 'hidden'}`}>
          <div className='w-full h-25 relative'>
            <textarea
              ref={ref}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onKeyDown={onKeyDown}
              className={`w-full h-full rounded-xl bg-dark-gray border border-secondary 
        resize-none outline-none text-white text-sms px-5 py-4 caret-color-delete ${
          props.ctxLabel ? 'rounded-bl-none' : ''
        }`}
            />
            {props.ctxLabel && (
              <p className='bg-lp-gray-003 text-icon-gray rounded px-2 py-1 absolute text-3xs bottom-0 left-0 transform-gpu translate-y-full rounded-t-none'>
                {props.ctxLabel}
              </p>
            )}
            <button
              type='button'
              className={`btn-delete w-7.5 h-7.5 rounded-xl flex items-center 
                justify-center absolute right-2 bottom-2`}
              disabled={isSubmitting}
              onClick={submit}
            >
              {isSubmitting ? (
                <Loading imgClassName='w-5 h-5' text='' />
              ) : (
                <ChatSendIcon />
              )}
            </button>
            <div
              className={`flex items-center justify-center bg-error text-sms 
              px-2 py-1 absolute bottom-1 left-1/2 transform -translate-x-1/2 
              rounded text-white transition-opacity ${
                showError ? 'opacity-100' : 'opacity-0'
              }`}
            >
              {err2s(error)}
            </div>
          </div>
          <div className='text-icon-gray text-3xs mt-1.5 text-center'>
            AI Editor can make mistakes. Please check your work.
          </div>
        </div>
        <button
          type='button'
          className={`w-full h-full rounded-xl bg-dark-gray border border-secondary 
        relative items-center justify-between p-3 ${
          !active ? 'flex' : 'hidden'
        }`}
          onClick={activateEditor}
        >
          <div className='flex items-center justify-center text-2xs text-icon-gray gap-1'>
            <AIIcon className='w-3.5 h-3.5 fill-current' />
            <p>AI Editor</p>
          </div>
          <p className='text-sms text-icon-gray'>⌘K</p>
        </button>
      </div>
    </div>
  );
}

export function UGCActivityPromptEditor(props: EditorProps) {
  return <CustomGamePackPromptEditor {...props} className={`mt-auto py-4`} />;
}
