import { Fragment, useState } from 'react';
import { createPortal } from 'react-dom';

import {
  type BlockFields,
  type JeopardyBlock,
  type JeopardyBoard,
} from '@lp-lib/game';
import { jeopardyBoardSchema } from '@lp-lib/shared-schema/src/ai/functions/zod/jeopardyBoard';
import { type JeopardyBoardVariable } from '@lp-lib/shared-schema/src/ai/variables/types/jeopardyBoard';

import { useLiveAsyncCall } from '../../../../hooks/useAsyncCall';
import { useLiveCallback } from '../../../../hooks/useLiveCallback';
import { apiService } from '../../../../services/api-service';
import { uuidv4 } from '../../../../utils/common';
import { useCancelConfirmModalStateRoot } from '../../../ConfirmCancelModalContext';
import { Loading } from '../../../Loading';
import { useEditor } from '../../Blocks/Common/Editor/EditorUtilities';
import { JeopardyBoardEditor } from '../../Blocks/Jeopardy/JeopardyBlockEditor';
import { BoardDirtyMap, JeopardyUtils } from '../../Blocks/Jeopardy/utils';
import { UGCActivityPromptEditor } from '../CustomGamePackPromptEditor';
import { type ActivityEditorProps } from '../types';
import { UGCDiffAcceptButton, UGCDiffRejectButton } from '../UGCDiffModal';
import { log, UGCUtils } from '../utils';

async function runAI(
  packId: string,
  block: JeopardyBlock,
  prompt: string,
  callback: (
    data: ReturnType<typeof jeopardyBoardSchema.parse>
  ) => Promise<void>
) {
  const variables: JeopardyBoardVariable = {
    currentBoardSize: JSON.stringify({
      numOfCategories: block.fields.boardSize.numRows,
      numOfCluesPerCategory: block.fields.boardSize.numCols - 1,
    }),
    currentBoard: JSON.stringify(block.fields.board),
  };
  const resp = await apiService.gamePack.generateBlock(packId, {
    blockId: block.id,
    userPrompt: prompt,
    variables,
  });
  log.info('generate block response', { data: resp.data });
  for (const step of resp.data.runSteps) {
    if (UGCUtils.VaildRunStep(step)) {
      const fn = step.stepDetails.tool_calls[0].function;
      await UGCUtils.UpdateWithLogging(packId, block, fn, async (data) => {
        const output = jeopardyBoardSchema.parse(data);
        for (const category of output.board.categories) {
          category.id = uuidv4();
          for (const clue of category.clues) {
            clue.id = uuidv4();
          }
        }
        log.info('post-processed board', { data: output });
        await callback(output);
      });
      return;
    }
  }
  throw new Error('No valid run step found');
}

export async function jeopardyGenAIAction(
  packId: string,
  block: JeopardyBlock,
  prompt: string
) {
  await runAI(packId, block, prompt, async (data) => {
    await apiService.block.updateFieldV2(block, 'board', data.board);
  });
}

function JeopardyBoardDiffModal(props: {
  block: JeopardyBlock;
  dirtyMap: BoardDirtyMap;
  onDiscard: () => void;
  onAccept: (board: BlockFields<JeopardyBlock>['board']) => Promise<void>;
}) {
  const { dirtyMap } = props;
  const [block, updateBlock] = useState<JeopardyBlock>(props.block);

  const updateField = useLiveCallback(
    async <K extends keyof JeopardyBlock['fields']>(
      field: K,
      value: JeopardyBlock['fields'][K]
    ) => {
      updateBlock({ ...block, fields: { ...block.fields, [field]: value } });
    }
  );

  return (
    <div className='w-screen h-screen z-50 fixed inset-0 bg-black flex items-center justify-center'>
      <section className='w-4/5 flex flex-col items-center justify-center gap-5 p-8'>
        <JeopardyBoardEditor
          block={block}
          setSavingChanges={() => void 0}
          setBusyMsg={() => void 0}
          fieldUpdater={updateField}
          dirtyMap={dirtyMap}
        />
        <footer className='flex justify-center items-center gap-5'>
          <UGCDiffRejectButton onClick={props.onDiscard} />
          <UGCDiffAcceptButton
            onClick={() => props.onAccept(block.fields.board)}
          />
        </footer>
      </section>
    </div>
  );
}

function buildBoardDirtyMap(
  oldBoard: JeopardyBoard,
  newBoard: JeopardyBoard,
  boardSize: JeopardyBlock['fields']['boardSize']
) {
  const oldItems = Object.values(
    JeopardyUtils.AdaptBoardTypes(oldBoard, boardSize).items
  );
  const newItems = Object.values(
    JeopardyUtils.AdaptBoardTypes(newBoard, boardSize).items
  );
  const dirtyMap = new BoardDirtyMap();
  for (let i = 0; i < newItems.length; i++) {
    const newItem = newItems[i];
    const oldItem = oldItems[i];
    if (!newItem || !oldItem || newItem?.type !== oldItem?.type) continue;
    if (newItem.type === 'category' && oldItem.type === 'category') {
      if (newItem.category !== oldItem.category) {
        dirtyMap.setCategoryDirty(newItem.id);
      }
    }
    if (newItem.type === 'clue' && oldItem.type === 'clue') {
      if (newItem.clue !== oldItem.clue) {
        dirtyMap.setClueDirty(newItem.id, 'clues');
      }
      if (newItem.answer !== oldItem.answer) {
        dirtyMap.setClueDirty(newItem.id, 'answers');
      }
      if (newItem.value !== oldItem.value) {
        dirtyMap.setClueDirty(newItem.id, 'points');
      }
    }
  }
  return dirtyMap;
}

export function JeopardyActivityEditor(
  props: ActivityEditorProps<JeopardyBlock>
): JSX.Element | null {
  const { packId, root, asset } = props;
  const { block, analytics } = props;
  const [busyMsg, setBusyMsg] = useState<string | undefined>();
  const { updateField } = useEditor(props);

  const [modal, triggerModal] = useCancelConfirmModalStateRoot();

  const {
    state: { state, error },
    call: onSubmitPrompt,
  } = useLiveAsyncCall(async (prompt: string) => {
    await runAI(packId, block, prompt, async (data) => {
      const previewBlock = {
        ...block,
        id: uuidv4(),
        fields: {
          ...block.fields,
          board: data.board,
          boardSize: JeopardyUtils.BoardSize(data.board),
        },
      };
      await triggerModal({
        kind: 'custom',
        noWrapper: true,
        element: (p) => (
          <JeopardyBoardDiffModal
            block={previewBlock}
            dirtyMap={buildBoardDirtyMap(
              block.fields.board,
              data.board,
              block.fields.boardSize
            )}
            onDiscard={p.internalOnCancel}
            onAccept={async (board) => {
              const boardSize = JeopardyUtils.BoardSize(board);
              await updateField('boardSize', boardSize);
              await updateField('board', board);
              p.internalOnConfirm();
            }}
          />
        ),
      });
    });
    return true;
  });

  return (
    <Fragment>
      {modal && root?.current && createPortal(modal, root.current)}
      <div className={`w-full h-full flex-col ${modal ? 'hidden' : 'flex'}`}>
        {busyMsg && (
          <div className='fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50'>
            <div className='flex flex-col items-center gap-2'>
              <Loading text='' />
              <p className='text-white'>{busyMsg}</p>
            </div>
          </div>
        )}
        <JeopardyBoardEditor
          {...props}
          csvUtilsEnabled
          setBusyMsg={setBusyMsg}
          fieldUpdater={updateField}
          onCategoryUpdated={(prev, next) => {
            analytics.trackJeopardyBoardCategoryEdited({
              blockId: block.id,
              nameChanged: prev.name !== next.name,
              scriptChanged: prev.nameScript !== next.nameScript,
            });
          }}
          onClueUpdated={(prev, next) => {
            analytics.trackJeopardyBoardClueEdited({
              blockId: block.id,
              questionChanged: prev.question !== next.question,
              answerChanged: prev.answer !== next.answer,
              awardChanged: prev.value !== next.value,
            });
          }}
          onCSVUploaded={(_, rows, cols) => {
            analytics.trackJeopardyBoardCSVUploaded({
              blockId: block.id,
              rows,
              cols,
            });
          }}
        />
        <UGCActivityPromptEditor
          enabled={!!props.enableAI}
          onSubmit={onSubmitPrompt}
          isSubmitting={state.isRunning}
          ctxLabel={`Editing ${asset.primaryText}`}
          error={error}
        />
      </div>
    </Fragment>
  );
}
